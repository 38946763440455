import Default from '../../../common/Themes/Default/Theme';
import { sectionHeadingAlignment, sectionHeadingHR } from '../../../common/utils/themeOverrides';
import * as boldIcons from '../../../common/IconPacks/boldOutline';
import * as socialIconPack from '../../../common/IconPacks/SocialIcons/defaultSocialIconPack';
import { Cascade } from '../../../common/loaders';
import { levelFilter } from '../../../common/utils/text';
import { WIDGETS_WITH_CIRCULAR_IMAGES } from '../../../common/constants';
import themeConfig from '../themeConfig';

class Theme10 extends Default {
  static config = themeConfig;

  static get displayName() {
    return 'Theme10';
  }

  static getMutatorDefaultProps(widgetType, widgetPreset) {
    const defaultProps = super.getMutatorDefaultProps(widgetType, widgetPreset);
    const enableCircularImage =
      WIDGETS_WITH_CIRCULAR_IMAGES[widgetPreset] || defaultProps.enableCircularImage;

    return widgetType === 'HEADER'
      ? {
        ...defaultProps,
        hasLogoAlign: true,
        showOverlayOpacityControls: true,
        enableVideoOverlaySlider: true,
        useSlideshow: true,
        headerTreatmentsConfig: {
          ...defaultProps.headerTreatmentsConfig,
          ...themeConfig.headerTreatmentsConfig
        }
      }
      : {
        ...defaultProps,
        enableCircularImage
      };
  }

  constructor() {
    super();
    this.mappedValues = {
      ...this.mappedValues,
      typographyOverrides: {
        LogoAlpha: {
          style: {
            font: 'primary',
            color: 'highContrast',
            fontSize: 'large',
            fontWeight: 'normal',
            letterSpacing: 'normal',
            textTransform: 'none'
          }
        },
        HeadingBeta: {
          style: {
            font: 'primary',
            color: 'highlight',
            fontSize: 'xxlarge',
            fontWeight: 'normal',
            letterSpacing: 'normal',
            textTransform: 'none'
          }
        },
        HeadingGamma: {
          style: {
            font: 'alternate',
            color: 'highlight',
            fontSize: 'xlarge',
            fontWeight: 'normal',
            letterSpacing: 'normal',
            textTransform: 'none'
          }
        },
        NavAlpha: {
          style: {
            font: 'alternate',
            color: 'highContrast',
            fontSize: 'medium',
            fontWeight: 'normal',
            letterSpacing: 'normal',
            textTransform: 'uppercase'
          }
        },
        SubNavAlpha: {
          style: {
            font: 'alternate',
            color: 'section',
            fontSize: 'medium',
            fontWeight: 'normal',
            letterSpacing: 'normal',
            textTransform: 'none'
          }
        },
        NavBeta: {
          style: {
            font: 'alternate',
            color: 'highContrast',
            fontSize: 'large',
            fontWeight: 'normal',
            letterSpacing: 'normal',
            textTransform: 'none'
          }
        },
        SubNavBeta: {
          style: {
            font: 'alternate',
            color: 'section',
            fontSize: 'medium',
            fontWeight: 'normal',
            letterSpacing: 'normal',
            textTransform: 'none'
          }
        },
        ButtonAlpha: {
          style: {
            font: 'alternate',
            fontWeight: 'bold',
            letterSpacing: '1px',
            textTransform: 'uppercase'
          }
        }
      }
    };
  }

  getSection(inputSection) {
    if (inputSection === 'default') {
      return 'alt';
    } else if (inputSection === 'overlay') {
      return 'overlay';
    }
    return 'default';
  }

  Heading(props) {
    const { tag, level } = props;
    const typography = {
      5: 'DetailsAlpha'
    }[levelFilter({ tag, level })];
    return super.Heading(this.merge({ typography }, props));
  }

  Input(props) {
    const { section } = props;
    return super.Input(
      this.merge(
        {
          section: this.getSection(section || this.base.section),
          style: {
            borderBottomWidth: 0,
            paddingBottom: 'small',
            backgroundColor: 'section',
            borderColor: 'input',
            paddingHorizontal: 'small',
            paddingTop: 'small',
            borderRadius: '0',
            borderStyle: 'solid'
          }
        },
        props
      )
    );
  }

  NavLinkActive(props) {
    return super.NavLinkActive(
      this.merge(
        {
          style: {
            borderBottomStyle: 'solid',
            borderWidth: '1px',
            paddingBottom: '2px',
            marginBottom: '-3px'
          }
        },
        props
      )
    );
  }

  Icon(props) {
    return super.Icon(
      this.merge(
        {
          iconPack: { ...boldIcons, ...socialIconPack }
        },
        props
      )
    );
  }

  Loader(props) {
    return Cascade.apply(this, [props]);
  }

  SectionHeading(props) {
    const { layout = 'full' } = props;
    const base = this.base;
    const overrides = this.merge({}, sectionHeadingAlignment(base), sectionHeadingHR(base));
    return super.SectionHeading(
      this.merge(
        {
          style: {
            textAlign: 'center',
            ['@md']: {
              textAlign: layout === 'full' ? 'center' : 'left'
            }
          }
        },
        overrides,
        props
      )
    );
  }

  Pipe(props) {
    return super.Pipe(
      this.merge(
        {
          style: {
            backgroundColor: 'sectionLowContrast'
          }
        },
        props
      )
    );
  }

  InputTextArea(props) {
    return super.InputTextArea(
      this.merge(
        {
          rows: 5
        },
        props
      )
    );
  }

  InputSelect(props) {
    const { section } = props;
    return super.InputSelect(
      this.merge(
        {
          section: this.getSection(section || this.base.section),
          style: {
            backgroundColor: 'section',
            borderColor: 'input',
            borderBottomWidth: 'medium',
            borderStyle: 'solid',
            borderRadius: 'none',
            ['@xs-only']: {
              fontSize: 'medium' // 16px
            }
          }
        },
        props
      )
    );
  }

  InputSelectElement(props) {
    return super.InputSelectElement(
      this.merge(
        {
          style: {
            paddingHorizontal: 'small',
            paddingTop: 'small',
            paddingBottom: '11px',
            ['@xs-only']: {
              fontSize: 'medium' // 16px
            }
          }
        },
        props
      )
    );
  }

  Table(props) {
    return super.Table(
      this.merge(
        {
          style: {
            borderRadius: 0
          }
        },
        props
      )
    );
  }

  MediaObjectBackground(props) {
    return super.MediaObjectBackground(
      this.merge(
        {
          style: {
            borderRadius: '0px'
          }
        },
        props
      )
    );
  }

  HeadingMajor(props) {
    return super.HeadingMajor(this.merge({ typography: 'HeadingBeta', featured: true }, props));
  }

  NavFooterLink(props) {
    return super.NavFooterLink(this.merge({ typography: 'LinkAlpha' }, props));
  }

  Tagline(props) {
    return super.Tagline(
      this.merge(
        {
          style: {
            wordWrap: 'break-word',
            overflowWrap: 'break-word',
            ['@xs-only']: {
              maxWidth: '100%',
              margin: '0 auto'
            }
          }
        },
        props
      )
    );
  }

  HeroText(props) {
    return super.SubTagline(
      this.merge(
        {
          style: {
            lineHeight: '1.5'
          }
        },
        props
      )
    );
  }

  Intro(props) {
    return super.Intro(this.merge({ alignment: 'center' }, props));
  }

  ImageLogo(props) {
    if (props.hasOverhang === false) {
      return super.ImageLogo(props);
    }
    return super.ImageLogo(
      this.merge(
        {
          style: {
            '@xs-only': {
              marginTop: 'medium'
            },
            '@sm-only': {
              marginTop: 'medium'
            }
          }
        },
        props
      )
    );
  }

  Phone(props) {
    const { logoOverhangHeight } = props;
    if (logoOverhangHeight === 0) {
      return super.Phone(props);
    }
    return super.Phone(
      this.merge(
        {
          style: {
            '@xs-only': {
              paddingTop: 'medium'
            },
            '@sm-only': {
              paddingTop: 'medium'
            }
          }
        },
        props
      )
    );
  }

  ContentCard(props) {
    const styles = this.base.widgetPreset === 'about1' ? { style: { alignItems: 'center' } } : {};
    return super.ContentCard(this.merge(styles, props));
  }

  SearchPopout({ section, ...props }) {
    return super.SearchPopout(
      this.merge(
        {
          section: section === 'alt' ? 'default' : 'alt',
          style: {
            borderStyle: 'none'
          }
        },
        props
      )
    );
  }
}

export default Theme10;
